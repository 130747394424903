import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {fetchP5, getDateString, getNewDate} from "../Utils";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {TableCell} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";

const FACTOR_WEATHER = 1;
const FACTOR_DISTANCE_TRAVELED = 2;
const FACTOR_REST = 3;
const FACTOR_INJURIES = 4;

function SSF(props) {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [footballEvents, setFootballEvents] = useState([]);
    const [eventFactors, setEventFactors] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    
    const fetchData = () => {
        fetchP5(props.getState().userName, props.getState().password, "/api/v1/ssf/pagedata", "ssf", props)
            .then(data => {
                processFactors();                
                setFootballEvents(props.getState().ssf.data.events.data);
                setEventFactors(props.getState().ssf.data.factors.data);
            });
    }
            
    useEffect(() => {
        document.title = "Sports Stats Football";
        if (!props.getState().isLoggedIn) {
            return navigate("/");
        }
        if (isLoading) {
            setIsLoading(false);
            fetchData();
        }
    }, []);

    function processFactors() {
        props.getState().ssf.data.events.data.forEach((event) => {
            let factors = props.getState().ssf.data.factors.data.filter((factor) => factor.event_id === event.id);
            event.factors = factors;
        })
    }
    
    function handleEventRowClick(e) {
        if (e.target.parentNode.id) {
            let ev = footballEvents.filter((fe) => fe.id === Number(e.target.parentNode.id));
            if (ev.length === 1) {
                setSelectedEvent(ev[0]);
            }
        }
    }
    
    function renderLoading() {
        return <div>Loading...</div>;
    }
    
    function renderFactorTeamTotal(event, team_id) {
        let positiveCount = event.factors.filter((factor) => factor.team_id === team_id && factor.points > 0).length;
        let negativeCount = event.factors.filter((factor) => factor.team_id === team_id && factor.points < 0).length;
        return (
            <span>
                (<span className="SSFPositiveFactors">{positiveCount}</span> / <span className="SSFNegativeFactors">{negativeCount}</span>)
            </span>
        );
    }
    function renderData() {
        return (
            <div className="SSFDataContainer">
                <TableContainer>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Away Team</TableCell>
                                <TableCell>Away Spread</TableCell>
                                <TableCell>Score</TableCell>
                                <TableCell>PScore</TableCell>
                                <TableCell>Home Team</TableCell>
                                <TableCell>Home Spread</TableCell>
                                <TableCell>Score</TableCell>
                                <TableCell>PScore</TableCell>
                                <TableCell>Over/Under</TableCell>
                                <TableCell>POU</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {footballEvents.map((event) =>
                                <TableRow onClick={handleEventRowClick} id={event.id} className="SSFEventRow">
                                    <TableCell>{getDateString(getNewDate(event.date))}</TableCell>
                                    <TableCell>{event.nickname1} {renderFactorTeamTotal(event, event.team1_id)}</TableCell>
                                    <TableCell>{event.spread1}</TableCell>
                                    <TableCell>{event.score1}</TableCell>
                                    <TableCell>{event.pscore1}</TableCell>
                                    <TableCell>{event.nickname2} {renderFactorTeamTotal(event, event.team2_id)}</TableCell>
                                    <TableCell>{event.spread2}</TableCell>
                                    <TableCell>{event.score2}</TableCell>
                                    <TableCell>{event.pscore2}</TableCell>
                                    <TableCell>{event.over_under}</TableCell>
                                    <TableCell>{Number(event.pscore1) + Number(event.pscore2)}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {selectedEvent != null ? renderEventFactors() : ''}
            </div>
        )
    }

    function renderEventFactorsForTeam(teamId) {
        return (
            <div>
                <span>{selectedEvent.team1_id === teamId ? selectedEvent.nickname1 : selectedEvent.nickname2}</span>
                <TableContainer className="SSFFactorTableContainer">
                    <Table sickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Factor</TableCell>
                                <TableCell>Points</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedEvent.factors.filter((factor) => factor.team_id === teamId).map((factor) =>
                                <TableRow>
                                    <TableCell>{factor.note}</TableCell>
                                    <TableCell>{factor.points}</TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell>{selectedEvent.factors.filter((factor) => factor.team_id === teamId).reduce((acc, current) => acc + Number(current.points), 0)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
    
    function renderEventFactors() {
        return (
            <div className="SSFEventFactorsContainer">
                {renderEventFactorsForTeam(selectedEvent.team1_id)}
                {renderEventFactorsForTeam(selectedEvent.team2_id)}
            </div>
        );
    }
    function handleUpdateButtonClick() {
        fetchP5(props.getState().userName, props.getState().password, '/api/v1/ssf/update')
            .then(data => {
                fetchData();
            });
    }
    
    return (
        <div>
            <div className="SSFHeaderBar">
                <h2>Sports Stats Football</h2>
                <Button size="small" onClick={handleUpdateButtonClick}>Update</Button>
            </div>
            {isLoading ? renderLoading() : renderData()}
        </div>
    );
}

export default SSF;